import { http, log } from "@/axios"

export default function () {
  const formatDuration = (duration: number) => {
    const min = Math.floor(duration / 60)
    const sec = duration % 60

    return min + ":" + (sec < 10 ? "0" + sec : sec)
  }

  const healthCheck = () => {
    const api = "/common/health"
    http.post(api).catch((error) => {
      console.error("error", error)
      if (error?.response?.status === 403) {
        // CSRFに起因すると思われるエラーは無視
        console.log("ignore 403")
        return
      }
      alert("サーバエラーが発生しました")
      log(api, error)
    })
  }

  return {
    formatDuration,
    healthCheck,
  }
}
